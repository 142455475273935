import React, { useState, useEffect, useRef, useLayoutEffect } from "react"
import { Helmet } from "react-helmet"
import Navbar, { LogoBrand, TextBrand } from "../components/Navbar"
import screenshot from "../images/screenshot.png"
import demo_webm from "../videos/demo.webm"
import demo_mp4 from "../videos/demo.mp4"
import AOS from "aos"
import "aos/dist/aos.css"
import "@fortawesome/fontawesome-free/css/all.css"
import "../mono.scss"

const DemoVideo = () => {
  const vidRef = useRef(null)
  const onClick = () => {
    vidRef.current.currentTime = 0
    vidRef.current.play()
  }
  return (
    <>
      <div className="demo-video">
        <video muted autoPlay ref={vidRef}>
          <source src={demo_webm} type="video/webm" />
          <source src={demo_mp4} type="video/mp4" />
        </video>
      </div>
      <button id="replayButton" className="button is-white" onClick={onClick}>
        <span className="icon">
          <i className="fas fa-redo"></i>
        </span>
        <span>Replay</span>
      </button>
    </>
  )
}

const IndexPage = () => {
  useEffect(() => {
    AOS.init()
  }, [])
  const [width, setWidth] = useState(324)
  const h1El = useRef(null)
  setTimeout(() => {
    setWidth(h1El.current.clientWidth + 5)
  }, 100)
  return (
    <>
      <Helmet>
        <title>Jeffrey - Study Buddy</title>
        <meta
          name="description"
          content="Never miss an assignment and connect with your university on Jeffrey."
        />
      </Helmet>
      <Navbar brand={TextBrand} />
      <section className="section" style={{ paddingTop: 30 }}>
        <div className="container">
          <div className="columns">
            <div className="column is-8">
              <div className="heading-bg">
                <div
                  style={{ width }}
                  className="highlight-background"
                  data-aos="reveal-right"
                  data-aos-delay="300"
                  data-aos-duration="1000"
                ></div>
                <h1 className="is-size-1 title" style={{ marginTop: "17%" }}>
                  <b>
                    <span className="highlight" ref={h1El}>
                      Meet Jeffrey.
                    </span>
                  </b>
                </h1>
              </div>
              <h3 className="is-size-3" style={{ marginTop: 15 }}>
                Your AI assistant for university studies. Never miss an
                assignment and connect with your university. Get started in less
                than a minute.
              </h3>
              <a
                className="button is-primary is-large is-rounded"
                href="https://m.me/getjeffreyai"
                style={{ margin: "20px auto" }}
              >
                <span>Connect on Messenger</span>
                <span className="icon">
                  <i className="fab fa-facebook-messenger"></i>
                </span>
              </a>
            </div>
            <div className="column is-4">
              <DemoVideo />
            </div>
          </div>
        </div>
      </section>
      <section className="section" style={{ paddingTop: 60 }}>
        <div className="container">
          <div className="columns">
            <div className="column is-8" data-aos="fade-right">
              <h1 className="title is-size-1 is-title">
                Never miss an assignment.
              </h1>
              <h3 className="is-size-3">
                Jeffrey reminds you when assignments are due, well before the
                due date. Start planning for your assessments in advance with
                weekly reminders and overviews of your due dates for your entire
                semester.
              </h3>
            </div>
            <div className="column" data-aos="fade-left">
              {/* <img
                src={screenshot}
                className="column-screenshot image-shadow"
                alt="Jeffrey - A virtual personal assistent."
              /> */}
            </div>
          </div>
        </div>
      </section>
      <section className="section" style={{ paddingTop: 60 }}>
        <div className="container">
          <div className="columns">
            <div className="column is-8" data-aos="fade-left">
              <h1 className="title is-size-1 is-title">
                Know what's on at your campus.
              </h1>
              <h3 className="is-size-3">
                Ask Jeffrey about events that are on, and see what's coming up,
                from barbecues to career events. Explore workshops, internships,
                and international experiences at the touch of a button.
              </h3>
            </div>
          </div>
        </div>
      </section>
      <section className="section" style={{ paddingTop: 60 }} id="contact">
        <div className="container">
          <div className="columns">
            <div className="column is-8" data-aos="fade-up">
              <h1 className="title is-size-1 is-title">Get in touch</h1>
              <a
                className="button is-primary is-large is-rounded"
                href="mailto:hello@getjeffrey.ai"
                style={{ margin: "20px auto" }}
              >
                <span>hello@getjeffrey.ai</span>
                <span className="icon">
                  <i className="fas fa-envelope"></i>
                </span>
              </a>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default IndexPage
